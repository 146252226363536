import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import * as moment from 'moment';
import Axios from "axios";

import { API_BASE_URL } from "./App";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center"
    },
    error: {},
    table: {}
}));

export default function PostDashboard() {
    const [closedData, setClosedData] = useState(null);
    const [receivedData, setReceivedData] = useState(null);
    const classes = useStyles();


    useEffect(() => {
        Axios.get(API_BASE_URL + "/posta/all_closed", { withCredentials: true })
            .then(resp => {
                setClosedData(resp.data);
            });
        Axios.get(API_BASE_URL + "/posta/all_received", { withCredentials: true })
            .then(resp => {
                setReceivedData(resp.data);
            });
    }, [setClosedData, setReceivedData]);

    return (
        <Grid container>
            <Grid item xs={6}>
                <TableContainer component={Paper}>
                    <Table
                        stickyHeader
                        className={classes.table}
                        aria-label="lezartak"
                        title="Lezart tetelek"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Vonalkod</TableCell>
                                <TableCell>Nev</TableCell>
                                <TableCell>EFJ zaras</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(closedData || []).map(item => (
                                <TableRow key={item.id}>
                                    <TableCell>{item.record_id}</TableCell>
                                    <TableCell>{item.recipient_name}</TableCell>
                                    <TableCell>{
                                        moment(item.efj_closing).format("YYYY MMMM Do H:mm")
                                    }</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={6}>
                <TableContainer component={Paper}>
                    <Table
                        stickyHeader
                        className={classes.table}
                        aria-label="atvettek"
                        title="Atvett tetelek"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Vonalkod</TableCell>
                                <TableCell>Osszeg</TableCell>
                                <TableCell>Árufizetési összeg elszámolási dátuma</TableCell>
                                <TableCell>Utalásra átadás dátuma</TableCell>
                                <TableCell>Feltoltes datuma</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(receivedData || []).map(item => (
                                <TableRow key={item.id}>
                                    <TableCell>{item.record_id}</TableCell>
                                    <TableCell>{item.amount} Ft</TableCell>
                                    <TableCell>{
                                        moment(item.date_of_payment, "YYYY.MM.DD").format("YYYY MMMM Do")
                                    }</TableCell>
                                    <TableCell>{
                                        moment(item.date_of_transfer, "YYYY.MM.DD").format("YYYY MMMM Do")
                                    }</TableCell>
                                    <TableCell>{
                                        moment(item.created).format("YYYY MMMM Do H:mm")
                                    }</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}