import { combineReducers } from "redux";
import { SET_UPLOAD_LIST, ADD_POSTA_MATCHES, POSTA_UPLOAD_ERROR } from "./actions";

function posta(state = {}, action) {
  switch (action.type) {
    case ADD_POSTA_MATCHES:
      return {
        ...state,
        postaMatches: [...(state.postaMatches || []), ...action.payload]
          .sort((a, b) => a.record_id - b.record_id)
          .filter(
            (val, ind, a) => !ind || val.record_id !== a[ind - 1].record_id
          )
      };
    case POSTA_UPLOAD_ERROR:
      return {
        ...state,
        postaError: action.payload
      };
    case SET_UPLOAD_LIST:
      return {
        ...state,
        uploadList: action.payload
      }
    default:
      return state;
  }
}

export const rootReducer = combineReducers({ posta });
