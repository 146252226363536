import React, { useCallback, useEffect } from "react";
import Axios from "axios";
import { API_BASE_URL } from "./App";
import { useSelector, useDispatch } from "react-redux";
import { addPostaMatches, addPostaUploadError } from "./redux/actions";
import { refreshUploadList } from "./UploadLog"
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import * as moment from 'moment';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { Alert, AlertTitle } from "@material-ui/lab";
import FormGroup from "@material-ui/core/FormGroup";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center"
  },
  error: {},
  table: {}
}));

function MatchList({ matchList }) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table
        stickyHeader
        className={classes.table}
        aria-label="egyezesek"
        title="Egyezesek"
      >
        <TableHead>
          <TableRow>
            <TableCell>Vonalkod</TableCell>
            <TableCell>Nev</TableCell>
            <TableCell>Osszeg</TableCell>
            <TableCell>Kezbesites datuma</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {matchList.map(match => (
            <TableRow key={match.record_id}>
              <TableCell>{match.record_id}</TableCell>
              <TableCell>{match.recipient_name}</TableCell>
              <TableCell>{match.amount} Ft</TableCell>
              <TableCell>{
                moment(match.efj_closing).format("YYYY MMMM Do H:mm")
              }</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function Posta() {
  const matchList = useSelector(state => state.posta.postaMatches || []);
  const uploadError = useSelector(state => state.posta.postaError);
  const dispatch = useDispatch();
  const classes = useStyles();

  const refresh = useCallback(() => {
    dispatch(addPostaUploadError(null));
    Axios.get(API_BASE_URL + "/posta/matches", { withCredentials: true })
      .then(resp => {
        dispatch(addPostaMatches(resp.data));
        refreshUploadList(dispatch);
      })
      .catch(e => console.error("failed to get posta matches", e));
  }, [dispatch]);

  const refreshError = useCallback(
    e => {
      dispatch(addPostaUploadError(e.response.data))
      refreshUploadList(dispatch);
    },
    [dispatch]
  );

  useEffect(() => refresh(), [refresh]);

  return (
    <div className={classes.root}>
      <Typography variant="h3" color="secondary">
        Posta
      </Typography>
      <Grid container>
        {uploadError ? (
          <Grid className={classes.error} item xs={12}>
            <Alert severity="error">
              <AlertTitle>Hiba tortent feltoltes kozben:</AlertTitle>
              {uploadError}
            </Alert>
          </Grid>
        ) : null}
        <Grid container item xs>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <UploadClosedInfo
                refreshCb={refresh}
                errorCb={refreshError}
              ></UploadClosedInfo>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <UploadReceivedInfo
                refreshCb={refresh}
                errorCb={refreshError}
              ></UploadReceivedInfo>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" color="secondary">
            Egyezesek
          </Typography>
          <MatchList matchList={matchList}></MatchList>
        </Grid>
      </Grid>
    </div>
  );
}

function UploadClosedInfo({ refreshCb, errorCb }) {
  const [openSuccess, setOpenSuccess] = React.useState(false);
  return (
    <>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenSuccess(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={() => setOpenSuccess(false)} severity="success">
          Sikeres feltoltes
        </Alert>
      </Snackbar>
      <form
        onSubmit={e =>
          submitClosedFile(e, 0, API_BASE_URL + "/posta/closed")
            .then(() => {
              setOpenSuccess(true);
              return refreshCb();
            })
            .catch(e => errorCb(e))
        }
      >
        <FormGroup>
          <InputLabel htmlFor="closedxml">Posta Lezart XML</InputLabel>
          <Input id="closedxml" type="file" variant="outlined" required></Input>
          <Button type="submit" color="primary" variant="outlined">
            Feltolt
          </Button>
        </FormGroup>
      </form>
    </>
  );
}

function UploadReceivedInfo({ refreshCb, errorCb }) {
  const [openSuccess, setOpenSuccess] = React.useState(false);
  return (
    <>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenSuccess(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={() => setOpenSuccess(false)} severity="success">
          Sikeres feltoltes
        </Alert>
      </Snackbar>
      <form
        onSubmit={e =>
          submitClosedFile(e, 0, API_BASE_URL + "/posta/received")
            .then(() => {
              setOpenSuccess(true);
              return refreshCb();
            })
            .catch(e => errorCb(e))
        }
      >
        <FormGroup>
          <InputLabel htmlFor="receivedcsv">Posta Atvett CSV</InputLabel>
          <Input
            type="file"
            id="receivedcsv"
            required
            variant="outlined"
          ></Input>
          <Button type="submit" color="primary" variant="outlined">
            Feltolt
          </Button>
        </FormGroup>
      </form>
    </>
  );
}

function submitClosedFile(event, childid, endpoint) {
  event.preventDefault();
  event.stopPropagation();
  const form = event.target;
  const payload = new FormData();
  payload.append("file", form[childid].files[0]);

  return Axios.post(endpoint, payload, {
    withCredentials: true
  }).then(() => {
    form.reset();
  });
}

export default Posta;
