import React, { useState, useEffect } from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Link as RouterLink
} from "react-router-dom";
import Axios from "axios";
import Grid from "@material-ui/core/Grid";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import ToolBar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";

import PostDashboard from "./PostDashboard";
import Posta from "./Posta";
import UploadLog from "./UploadLog";

export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8000";

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: { main: "#7C93D7" },
    secondary: { main: "#BFCDF5" }
  }
});

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [displayName, setDisplayName] = useState(false);

  useEffect(() => {
    Axios.get(API_BASE_URL, { withCredentials: true })
      .then(resp => {
        setIsLoggedIn(true);
        setDisplayName(resp.data);
      })
      .catch(e => console.error("failed to query the api", e));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <ToolBar>
          {displayName ? (
            <>
              <Typography>{`Bejelentkezve mint ${displayName}`}</Typography>
              <Logout ></Logout>
            </>
          ) : (
              <LoginGoogle isLoggedIn={isLoggedIn}></LoginGoogle>
            )}
        </ToolBar>
      </AppBar>
      <Router>
        <div>
          <ul>
            <li>
              <RouterLink to="/">
                <Link>
                  Home
              </Link>
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/posta-dashboard">
                <Link>
                  Posta
              </Link>
              </RouterLink>
            </li>
          </ul>

          <hr />

          {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
          <Switch>
            <Route exact path="/">
              <Content isLoggedIn={isLoggedIn}></Content>
            </Route>
            <Route path="/posta-dashboard">
              <PostDashboard />
            </Route>
          </Switch>
        </div>
      </Router>
    </ThemeProvider>
  );
}

function Content({ isLoggedIn }) {
  if (!isLoggedIn) return null;
  return (
    <Grid container>
      <Grid item xs={4}>
        <UploadLog></UploadLog>
      </Grid>
      <Grid item xs={8}>
        <Posta></Posta>
      </Grid>
    </Grid>
  );
}

function Logout() {
  return (
    <Link
      href={API_BASE_URL + "/logout"}
      rel="noopener noreferrer"
      color="textPrimary"
    >
      <Typography>Log out</Typography>
    </Link>
  );
}


function LoginGoogle({ isLoggedIn }) {
  if (!isLoggedIn)
    return (
      <Link
        href={API_BASE_URL + "/login/google"}
        rel="noopener noreferrer"
        color="textPrimary"
      >
        <img
          width="20px"
          style={{ marginRight: "8px" }}
          alt="Google sign-in"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
        />
        <Typography>Google Sign-in</Typography>
      </Link>
    );
  return null;
}

export default App;
