import React, { useEffect } from "react";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setUploadList } from "./redux/actions";
import * as moment from "moment";
import { API_BASE_URL } from "./App";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center"
  },
  uploadListRowError: {
    backgroundColor: theme.palette.error.light,
    backgroundBlendMode: "overlay",
    color: theme.palette.error.contrastText
  },
  uploadListRowSuccess: {
    backgroundColor: theme.palette.success.light,
    backgroundBlendMode: "screen",
    color: theme.palette.success.contrastText
  }
}));

export const refreshUploadList = async (dispatch) => {
  const resp = await Axios.get(API_BASE_URL + "/upload-log", { withCredentials: true });
  const action = setUploadList(resp.data);
  dispatch(action);
};

export default function Uploads(props) {
  const uploadList = useSelector(state => state.posta.uploadList || []);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    refreshUploadList(dispatch);
  }, [dispatch]);

  return (
    <>
      <Typography variant="h3" color="secondary">
        Korabbi feltoltesek
      </Typography>
      <TableContainer>
        <Table>
          <TableBody>
            {(uploadList || []).map(row => (
              <TableRow key={row[0]}>
                <TableCell
                  className={
                    (!row[2] && classes.uploadListRowError) ||
                    classes.uploadListRowSuccess
                  }
                >
                  {moment(row[0]).format("YYYY MMMM Do H:mm")}
                </TableCell>
                <TableCell
                  className={
                    (!row[2] && classes.uploadListRowError) ||
                    classes.uploadListRowSuccess
                  }
                >
                  {row[1]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
