export const ADD_POSTA_MATCHES = "ADD_POSTA_MATCHES";
export const POSTA_UPLOAD_ERROR = "POSTA_UPLOAD_ERROR";
export const SET_UPLOAD_LIST = "SET_UPLOAD_LIST"

export const setUploadList = payload => ({ type: SET_UPLOAD_LIST, payload });

export const addPostaMatches = payload => ({
  type: ADD_POSTA_MATCHES,
  payload
});

export const addPostaUploadError = payload => ({
  type: POSTA_UPLOAD_ERROR,
  payload
});
